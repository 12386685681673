import axios from "@/api/http";
import {Toast, Dialog} from "vant";

export default {
    config: {
        fileName: 'upfile',//字段名称
        fileType: 'uploadimage'//文件类型
    },
    isAssetTypeAnImage(ext) {

        ext = ext.toLowerCase()
        return [
            'png', 'jpg', 'jpeg', 'bmp', 'gif', 'webp'].indexOf(ext.toLowerCase()) !== -1;
    },
    //获取文件后缀
    getFileType(filePath) {
        //获取最后一个.的位置
        var index = filePath.lastIndexOf(".");
        //获取后缀
        return filePath.substr(index + 1).split('!')[0];
    },
    /**
     * 上传文件
     * @param file 上传的文件
     * @param fn 回掉函数
     * @param fileName 字段名称
     */
    uploader(file, fn, config = {}) {
        if (!this.isAssetTypeAnImage(this.getFileType(file.name))) {
            //自动切换文件类型
            this.config.fileType = "uploadfile"
        } else {
            this.config.fileType = "uploadimage"
        }


        //upfile
        this.config = Object.assign({}, this.config, config);
        let param = new FormData()
        param.append(this.config.fileName, file)
        //uploadfile
        //uploadimage
        axios.post("/user/ueditor/upload/action/" + this.config.fileType, param, {
            headers: {"Content-Type": "multipart/form-data"},
            isFile: true,
            load: false
        }).then(da => {
            if (typeof da.state != "undefined" && da.state == "SUCCESS") {
                fn({
                    code: 1,
                    msg: "上传成功",
                    fileName: da.file_name,
                    url: da.url,
                    status: "done",
                })
            } else {
                fn({
                    code: 0,
                    msg: "上传失败",
                    status: "failed",
                })
            }

        }).catch(() => {
            fn({
                code: 0,
                msg: "上传失败",
                status: "failed",
            })
            Dialog({message: "上传失败"});
        })

    },

    /**
     * 获取预览文件
     * 不图片就返回对应胡格式
     * @param val
     * @returns {*}
     */
    getYuLanFile(val) {
        let geShi = this.getFileType(val).toLowerCase();
        if (geShi == 'pdf') {
            return require('../assets/icon/pdf.png')
        } else if (this.isAssetTypeAnImage(geShi)) {
            return val
        } else {
            return require('../assets/icon/docx.png')
        }

    }
}