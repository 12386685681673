<template>
  <van-popup
      teleport="body"
      :overlay-style="overlayStyle"
      class="cao-jian-zhe-zhao"
      @touchmove.stop.prevent="moveHandle"
      v-model:show="isShow"
      @close="guanBi"
      :style="{ height: '100%',width:'100%' }">
    <div class="div-bg">
      <div>
        <div class="cai-jian">
          <div class="img-container">
            <img class="bg-tu" :src="afterImg" ref="imageDemo" alt="">
          </div>
        </div>
        <div class="an-niu">
          <van-row>
            <van-col span="12" style="text-align: center">
              <van-button @click="guanBi">取消</van-button>
            </van-col>
            <van-col span="12" style="text-align: center">
              <van-button type="primary" @click="sureSava">确定</van-button>
            </van-col>
          </van-row>
        </div>
      </div>
    </div>
  </van-popup>
</template>

<script>
export default {
  name: 'caiJian',
}
</script>
<script setup>
import {useContext, ref, onMounted, nextTick, defineProps, defineEmit} from "vue";
import Cropper from "cropperjs";
import 'cropperjs/dist/cropper.css'

const emit = defineEmit(['lol-success'])
const ext = useContext()
const props = defineProps({
  biLi: {
    type: Number,
    default() {
      return 1
    }
  }
})
let afterImg = ref('')
let myCropper = ref('')
let isShow = ref(false)
const overlayStyle = ref({
  'background-color': 'rgba(24,24,25, 1)'
})

const imageDemo = ref(null);


function show(image) {
  afterImg.value = image
  isShow.value = true
  nextTick(() => {
    myCropper.value = new Cropper(imageDemo.value, {
      viewMode: 1,
      dragMode: 'move',
      rotatable: true,
      scalable: true,
      aspectRatio: props.biLi,//设置比例
      zoomOnTouch: true,
      preview: '.before',
      background: false,
      autoCropArea: 0.6,
      zoomOnWheel: false,
      cropBoxMovable: true,
      checkOrientation: true,

    })
  })

}

function moveHandle() {
  return false
}

function sureSava() {
  let image = myCropper.value.getCroppedCanvas({
    imageSmoothingQuality: 'high'
  }).toDataURL(myCropper.value)
  try {
    isShow.value = false
    myCropper.value.destroy()
  } catch (e) {
    console.log('裁剪移除失败')
  }
  emit('lol-success', image)
}

function guanBi() {
  try {
    isShow.value = false
    myCropper.value.destroy()
  } catch (e) {
    console.log('裁剪移除失败')
  }
}

ext.expose({
  show
})
</script>

<style scoped lang="less">
@import "../../assets/basis_css/style/var";

.cai-jian {
  display: flex;
}

.before {
  width: 100px;
  overflow: hidden;
}

.img-container {
  overflow: hidden;
  text-align: center;
  height: 400px;
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: space-around;
  justify-content: space-around;
  align-items: center;

  .bg-tu {
    width: 100%;
  }
}

.an-niu {
  position: fixed;
  bottom: 10px;
  width: 100%;
  left: 0;
}

.div-bg {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: center;
  align-items: center;
}


</style>
<style lang="less">
@import "../../assets/basis_css/style/var";

.cao-jian-zhe-zhao {
  background-color: @gray-8;
}
</style>
