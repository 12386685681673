<template>
  <div>
    <van-uploader
        :deletable="!disabled"
        :disabled="disabled"
        :accept="fileType"
        v-model="fileList"
        @delete="dateEdit"
        :max-count="getLength"
        :after-read="afterRead"
    >
      <template #preview-cover="item">
        <van-image class="zhe-zhao-tu"
                   :src="getYuLanTu(item.url)"
                   fit="scale-down"
                   @click="isDownload(item)"
        />
        <div class="zhe-zhao-name">
          <div class="preview-cover van-ellipsis" @click.stop="">
            {{ item.name }}
          </div>
        </div>
      </template>
    </van-uploader>
  </div>
  <cai-jian :bi-li="biLi" ref="caiJian" @lol-success="caiJianOk"/>
</template>

<script>
import Img from "@/api/Img"
import XEUtils from "xe-utils";
import CaiJian from "@/components/file/caiJian";
import {b64HuanFile, getFileType, getImageTypeList, getShowUrl, getToken} from "@/api/file";
import * as qiniu from 'qiniu-js'
import moment from "moment";
//上传图片

export default {
  name: 'AddImg',
  props: {
    //是否显示下载按钮
    showDownload: {
      type: Boolean,
      default() {
        return true
      }
    },
    caiJian: Boolean,//true=启动裁剪
    biLi: Number,//裁剪比例

    form: {
      type: [Array, String],
      default() {
        return []
      }
    },
    list: {
      type: Array,
      default() {
        return []
      },

    },
    length: {
      type: Number,
      default() {
        return 6
      }
    },
    fileType: {
      type: String,
      default() {
        return "image/*"
      }
    },

  },
  components: {CaiJian, FileList},
  data() {
    return {
      config: {},
      url: [],
      form_list: [],
      fileList: [],
      dangQianData: {},
    }
  },
  mounted() {
    //验证格式符合 就显示数据
    if (Array.isArray(this.list)) {
      this.list.forEach(n => {
        if (typeof n.url != "undefined" && n.img != '') {
          this.fileList.push(n)
        }
      })
    }

  },
  methods: {
    isDownload(url) {
      let type = getFileType(url.url)
      //不是图片直接下载
      if (!getImageTypeList.includes(type)) {
        let lian_jie = `${url.url}?attname=${url.name}`;
        location.href = lian_jie
      }
    },
    getYuLanTu(url) {
      return getShowUrl(url)
    },
    caiJianOk(b64) {
      this.shangCuan(b64HuanFile(b64, this.dangQianData.file.name))
    },
    //添加事件
    afterRead(file) {
      this.fileList = XEUtils.slice(this.fileList, 0, this.fileList.length - 1)
      if (this.caiJian) {
        this.dangQianData = file
        this.$refs.caiJian.show(file.content)
      } else {
        this.shangCuan(file.file)
      }
    },
    async shangCuan(file) {
      const self = this
      let config = await getToken(file)
      const fileType = Img.getFileType(file.name)
      const fileName = `${moment().unix()}${XEUtils.random(1000, 9999)}.${fileType}`
      const observable = qiniu.upload(file, fileName, config.data.token, {}, {})
      observable.subscribe({
        complete(res) {
          self.fileList.push({
            url: `${config.data.host}/${res.key}`,
            status: 'done',
            name: file.name
          })
          console.log(self.fileList)
          self.dateEdit()
        }
      })
    },
    //触发删除事件
    dateEdit() {
      let self = this
      this.url = []
      this.form_list = []
      this.fileList.forEach((n) => {
        if (n.status == "done") {
          this.url.push(n.url)
          this.form_list.push({
            name: n.name,
            url: n.url
          })
        }
      })
      this.$nextTick(() => {
        this.$emit("update:form", this.form_list)
        this.$emit("update:url", this.url.join(','))
        this.$emit("change", this.fileList)
      })
    }

  },
  watch: {
    form: {
      handler(newList) {
        this.fileList = newList.map(n => {
          return {
            ...{
              status: 'done',
            }, ...n
          }
        })
        console.log(newList)
      },
      immediate: true
    },

  },
  computed: {
    getLength() {
      if (this.disabled) {
        return this.fileList.length
      }
      return this.length
    }
  }
}

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
.preview-cover {
  position: absolute;
  bottom: 0;
  box-sizing: border-box;
  width: 100%;
  padding: 4px;
  color: #fff;
  font-size: 12px;
  text-align: center;
  background: rgba(0, 0, 0, 0.3);
}

.zhe-zhao-tu {
  width: 100%;
  height: 100%;
  background-color: #fff;
}

.zhe-zhao-name {
  position: absolute;
  bottom: 0;
  width: 100%;
  background-color: #00000080;
  line-height: 14px;
  font-size: 14px;
  height: 25px;
}

.preview-cover a {
  color: #fff;
}
</style>
